import * as Yup from 'yup';
import { minAgeSchema, postalCodeSchema } from 'utils/validation';
import { format } from 'date-fns';
import { GENDER_OPTIONS, RELATIONSHIP_STATUS_OPTIONS, RELIGION_OPTIONS } from 'components/types/options';
import withFormSubmission, { MergedProps } from 'components/common/withFormSubmission';
import { UserOptionType } from 'components/userProfile/UserDemographicsSection/withUserDemographicsSectionForm';
import { TFunction } from 'next-i18next';

export interface IValues {
  birth_date?: Date | string;
  gender: string;
  religion: string;
  jewish_descendants: number[];
  relationship_status: string;
}

export interface IProps {
  t: TFunction;
  user: any;
  jewishDescendants: UserOptionType[];
  onSubmit: (values: IValues) => void;
}

export type FormComponentProps = MergedProps<IProps, IValues>;

const withUserInfoForm = withFormSubmission<IProps, IValues>({
  validationSchema: (props: IProps) =>
    Yup.object({
      postal_code: postalCodeSchema().required(),
      birth_date: minAgeSchema(13, props.t('common:birthDateErrors.less13Years')).required(),
      gender: Yup.mixed().oneOf(GENDER_OPTIONS).required(),
      religion: Yup.mixed().oneOf(RELIGION_OPTIONS).nullable(),
      jewish_descendants: Yup.array(Yup.number()),
      relationship_status: Yup.mixed().oneOf(RELATIONSHIP_STATUS_OPTIONS).nullable(),
    }),
  mapPropsToValues: ({ user }) => ({
    postal_code: user?.address?.postal_code || '',
    birth_date: (user?.birth_date && new Date(user?.birth_date)) || null,
    gender: user?.gender || '',
    religion: user?.religion || '',
    jewish_descendants: user.jewish_descendants.map(({ id }: { id: number }) => id),
    relationship_status: user?.relationship_status || '',
  }),
  handleSubmit: (values, { props: { onSubmit } }) => {
    const { birth_date, postal_code, ...rest } = values as any;
    rest.birth_date = format(birth_date, 'yyyy-MM-dd');
    rest.address = {};
    rest.address.postal_code = postal_code;
    onSubmit(rest);
  },
});

export default withUserInfoForm;
