import React from 'react';
import { Grid, TextField } from '@mui/material';
import { Field, Form } from 'formik';
import { useTranslation } from 'i18n';

import Button from 'components/common/Button';
import { FieldToTextField } from 'components/common/FormField';
import withMfaCodeForm, { FormComponentProps } from './withMfaCodeForm';

const MfaCodeForm: React.FC<React.PropsWithChildren<FormComponentProps>> = ({ isSubmitting }) => {
  const [t] = useTranslation('public');

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Grid container spacing={4} justifyContent="center" component={Form}>
      <Grid item xs={12}>
        <Field
          fullWidth
          variant="outlined"
          name="code"
          placeholder={t<string>('auth.authMfaModal.placeholder')}
          component={FieldToTextField}
          TextField={TextField}
        />
      </Grid>
      <Grid item>
        <Button rounded type="submit" color="secondary" variant="contained" disabled={isSubmitting}>
          {t<string>('common:button.submit')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default withMfaCodeForm(MfaCodeForm);
