import * as Yup from 'yup';
import { emailSchema } from 'utils/validation';
import withFormSubmission, { MergedProps } from 'components/common/withFormSubmission';

export interface IValues {
  email: string;
  password: string;
}

export interface IProps {
  onSubmit: (values: IValues) => void;
  onMlSwitch: () => void;
  onForgotPassword: () => void;
  errorMessage: string;
}

export type FormComponentProps = MergedProps<IProps, IValues>;

const withLoginForm = withFormSubmission<IProps, IValues>({
  validationSchema: () =>
    Yup.object({
      email: emailSchema().required(),
      password: Yup.string().required(),
    }),
  mapPropsToValues: () => ({
    email: '',
    password: '',
  }),
  handleSubmit: (values, { props: { onSubmit } }) => {
    onSubmit(values);
  },
});

export default withLoginForm;
