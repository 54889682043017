import React from 'react';
import { Box, Grid, TextField } from '@mui/material';
import { Field, Form } from 'formik';
import { useTranslation } from 'i18n';
import { FieldToTextField } from 'components/common/FormField';
import Button from 'components/common/Button';
import withMagicLinkLoginForm, { FormComponentProps } from './withMagicLinkLoginForm';

const MagicLinkLoginForm: React.FC<React.PropsWithChildren<FormComponentProps>> = (props) => {
  const { isSubmitting } = props;
  const [t] = useTranslation('public');

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Grid style={{ paddingTop: 12 }} container spacing={3} component={Form}>
      <Grid item xs={12}>
        <Field
          fullWidth
          variant="outlined"
          name="email"
          autoComplete="email"
          placeholder={t<string>('common:field.placeholder.emailShort')}
          component={FieldToTextField}
          TextField={TextField}
        />
      </Grid>
      <Grid item xs={12}>
        <Box mt={2} textAlign="center">
          <Button rounded type="submit" color="primary" variant="contained" disabled={isSubmitting}>
            {t<string>('auth.loginForm.submit')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default withMagicLinkLoginForm(MagicLinkLoginForm);
