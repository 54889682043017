import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ParsedUrlQueryInput } from 'querystring';
import { RootState } from 'store/rootReducer';
import useInfiniteScrollLoader from './useInfiniteScrollLoader';

export type EntitiesState<E> = {
  entities: E[];
  count?: number;
  fetchingError: any;
  isFetching: boolean;
  isFetched: boolean;
  additionalInfo?: any;
};

export type PaginationState = {
  count: number;
  hasNext: boolean;
};

export type LoaderOptions = {
  skipInitialLoad?: boolean;
  defaultQueryParams?: ParsedUrlQueryInput;
  hiddenQueryParams?: ParsedUrlQueryInput;
  queryParams?: ParsedUrlQueryInput;
};

export type LoaderArgs<E> = {
  actions: any;
  stateSelector: <S, P>(state: S, props?: P) => EntitiesState<E>;
  options: LoaderOptions;
};

export type InfinityLoaderArgs<E> = LoaderArgs<E> & {
  paginationSelector: <S, P>(state: S, props?: P) => PaginationState;
};

/**
 * A utility function for merging query params
 *
 *  @param defaultQueryParams The default query params (hidden in URL, can be overridden other query params)
 *  @param queryParams The query params (showed in URL and can be overridden by hidden query params)
 *  @param hiddenQueryParams The hidden query params (hidden in URL, can't be overridden)
 *  @returns The merged query params
 */
export const mergeQueries = (
  defaultQueryParams: ParsedUrlQueryInput,
  queryParams: ParsedUrlQueryInput,
  hiddenQueryParams: ParsedUrlQueryInput,
) => ({
  ...defaultQueryParams,
  ...queryParams,
  ...hiddenQueryParams,
});

export const useLoader = <E>(args: LoaderArgs<E>) => {
  const { actions, stateSelector, options } = args;
  const { defaultQueryParams, hiddenQueryParams, queryParams, skipInitialLoad } = options;

  const dispatch = useDispatch();

  const skipLoad = useRef(skipInitialLoad);

  const memoizedStateSelector = useCallback(
    (state: RootState) => stateSelector(state, mergeQueries(defaultQueryParams, queryParams, hiddenQueryParams)),
    [stateSelector, defaultQueryParams, queryParams, hiddenQueryParams],
  );

  const state = useSelector(memoizedStateSelector);

  const handleReset = useCallback(() => {
    dispatch(actions.reset({ params: mergeQueries(defaultQueryParams, queryParams, hiddenQueryParams) }));
  }, [dispatch, actions, defaultQueryParams, queryParams, hiddenQueryParams]);

  const handleLoad = useCallback(() => {
    dispatch(actions.request({ params: mergeQueries(defaultQueryParams, queryParams, hiddenQueryParams) }));
  }, [dispatch, actions, defaultQueryParams, queryParams, hiddenQueryParams]);

  useEffect(() => {
    if (skipLoad.current) {
      skipLoad.current = false;
      return;
    }
    handleReset();
    handleLoad();
  }, [handleReset, handleLoad]);

  return {
    state,
    handleLoad,
    handleReset,
  };
};

export const usePaginatedLoader = <E>(args: InfinityLoaderArgs<E>) => {
  const { paginationSelector, ...other } = args;
  const { options } = other;
  const { defaultQueryParams, queryParams, hiddenQueryParams } = options;

  const memoizedPaginatedSelector = useCallback(
    (state: any) => paginationSelector(state, mergeQueries(defaultQueryParams, queryParams, hiddenQueryParams)),
    [paginationSelector, defaultQueryParams, queryParams, hiddenQueryParams],
  );

  const props = useLoader(other);

  const pagination = useSelector(memoizedPaginatedSelector);

  return {
    ...props,
    pagination,
  };
};

export const useInfinityLoader = <E>(args: InfinityLoaderArgs<E>) => {
  const props = usePaginatedLoader(args);

  const [setElementWrapper, setElementChild] = useInfiniteScrollLoader(
    props.handleLoad,
    props.state.isFetching,
    props.pagination.hasNext,
  );

  return {
    ...props,
    setElementWrapper,
    setElementChild,
  };
};
