import React from 'react';
import PropTypes from 'prop-types';
import { fieldToInputBase } from 'formik-mui';
import { useTranslation } from 'i18n';
import { getLabel, getPlaceholder } from './translations';

function FieldToInputBase({ InputBase, label, placeholder, namespace, context, ...props }) {
  const [t] = useTranslation();
  const { name, ...fieldProps } = fieldToInputBase(props);

  if (!fieldProps.value) fieldProps.value = '';

  return (
    <InputBase
      {...fieldProps}
      name={name}
      label={getLabel(t, namespace, context, name, label)}
      placeholder={getPlaceholder(t, namespace, context, name, placeholder)}
    />
  );
}

FieldToInputBase.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  namespace: PropTypes.string,
  context: PropTypes.string,
  InputBase: PropTypes.elementType.isRequired,
};

export default FieldToInputBase;
