import React from 'react';
import { useTranslation } from 'i18n';
import { makeStyles } from 'tss-react/mui';
import useTextFieldProps, { FieldProps, TextFieldProps } from './useTextFieldProps';

export type FieldToTextFieldProps<P extends TextFieldProps, C extends React.ElementType<P>> = FieldProps &
  Omit<React.ComponentPropsWithRef<C>, keyof FieldProps> & {
    TextField: C;
  };

const useStyles = makeStyles()((theme) => ({
  root: {
    '&&& .MuiSelect-select': {
      height: 32,
      paddingRight: '40px !important',
      lineHeight: 2.1,
    },
    '& label.Mui-focused': {
      color: theme.palette.text.primary,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.text.secondary,
      },
    },
  },
}));

const FieldToTextField = <P extends TextFieldProps, C extends React.ElementType<P>>(
  props: FieldToTextFieldProps<P, C>,
) => {
  // TODO:
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { TextField, ...rest } = props;

  const { classes } = useStyles();
  const [t] = useTranslation();

  // TODO:
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const fieldProps: any = useTextFieldProps(rest, t);

  const handleChange: P['onChange'] = (e) => {
    if (e instanceof Event) return fieldProps.onChange(e);

    const execute = fieldProps.onChange(fieldProps.name);
    if (typeof execute === 'function') {
      execute(e);
    }
  };

  return (
    <TextField
      className={classes.root}
      {...fieldProps}
      onChange={handleChange}
      sx={{
        '& legend': { display: 'none' },
        '& .MuiOutlinedInput-notchedOutline': {
          top: '0 !important',
        },
      }}
    />
  );
};

export default FieldToTextField;
