import { useCallback, useEffect, useRef, useState } from 'react';

const calcBottom = (child: HTMLElement | null, wrapper: any, threshold: number) => {
  const { scrollTop: childScrollTop, offsetHeight: childHeight } = child || {};
  const {
    innerHeight,
    offsetHeight: wrapperHeight,
    scrollTop: wrapperScrollTop,
    clientHeight,
    scrollHeight,
  } = wrapper || {};

  let isBottom = (wrapperHeight || innerHeight) + (wrapperScrollTop || childScrollTop) > childHeight - threshold;
  if (!child && wrapper) {
    isBottom = clientHeight + wrapperScrollTop > scrollHeight - threshold;
  }
  return isBottom;
};

const useInfiniteScrollLoader = (callback: () => void, isLoading: boolean, hasMore: boolean, threshold = 50) => {
  const isListen = useRef(true);
  const [wrapper, setElementWrapper] = useState<any>();
  const [child, setElementChild] = useState<any>();
  isListen.current = !isLoading && hasMore;

  const handleLoad = useCallback(() => {
    const isBottom = calcBottom(child, wrapper, threshold);

    if (isListen.current && wrapper) {
      if (isBottom) {
        callback();
        isListen.current = false;
      }
    }
  }, [child, wrapper, threshold, callback]);

  useEffect(() => {
    if (!hasMore) {
      return;
    }

    if (wrapper) {
      wrapper.addEventListener('scroll', handleLoad);
      wrapper.addEventListener('resize', handleLoad);

      return () => {
        wrapper.removeEventListener('scroll', handleLoad);
        wrapper.removeEventListener('resize', handleLoad);
      };
    }
  }, [child, handleLoad, hasMore, wrapper]);

  return [setElementWrapper, setElementChild];
};

export default useInfiniteScrollLoader;
