import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthSelectors, OptionsSelectors } from 'store/selectors';
import { OptionsActions, UserActions } from 'store/actions';
import InterestView from './InterestView';

export type InterestViewContainerProps = {
  onNext: () => void;
};

const InterestViewContainer: React.FC<React.PropsWithChildren<InterestViewContainerProps>> = (props) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(AuthSelectors.currentUser);
  const interestsCategories = useSelector(OptionsSelectors.interestsCategories);
  const interestsItems = useSelector(OptionsSelectors.interestsItems);

  const handleToggleUserInterests = (id: number) => {
    dispatch(UserActions.toggleUserInterest(id));
  };

  useEffect(() => {
    dispatch(OptionsActions.getUserInterests.request({ params: { page_size: 100 } }));
  }, [dispatch]);

  useEffect(
    () => () => {
      dispatch(UserActions.partialUpdateUser.reset({ id: currentUser.id }));
    },
    [currentUser, dispatch],
  );

  return (
    <InterestView
      {...props}
      user={currentUser}
      interestsCategories={interestsCategories}
      interestsItems={interestsItems}
      onToggle={handleToggleUserInterests}
    />
  );
};

export default memo(InterestViewContainer);
