import * as Yup from 'yup';
import withFormSubmission, { MergedProps } from 'components/common/withFormSubmission';

export interface IValues {
  code: string;
}

export interface IProps {
  onSubmit: (values: IValues) => void;
  onResetPhone: () => void;
  onResend: () => void;
  error: any;
}

export type FormComponentProps = MergedProps<IProps, IValues>;

const withVerificationFieldForm = withFormSubmission<IProps, IValues>({
  validationSchema: () =>
    Yup.object({
      code: Yup.string().length(6),
    }),
  mapPropsToValues: () => ({
    code: '',
  }),
  handleSubmit: (values, { props: { onSubmit } }) => {
    onSubmit(values);
  },
});

export default withVerificationFieldForm;
