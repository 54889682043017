import React from 'react';
import { CircularProgress, Dialog, DialogProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useDialogStyles = makeStyles()({
  paper: {
    overflow: 'hidden',
  },
});

const PaperComponent = 'div' as any;

const LoaderModal = (props: DialogProps) => {
  const { onClose, ...rest } = props;
  const { classes: dialogClasses } = useDialogStyles();
  return (
    <Dialog classes={dialogClasses} onClose={onClose} PaperComponent={PaperComponent} {...rest}>
      <CircularProgress />
    </Dialog>
  );
};

export default LoaderModal;
