export const submitForm = (formRef, onlyDirty = true) => {
  let form = formRef;
  if (formRef && 'current' in formRef) {
    form = formRef.current;
  }

  if (form && !form.isSubmitting && (form.dirty || !onlyDirty)) {
    return form.submitForm();
  }
};

export const submitForms = (formRefs, onlyDirty = true) => {
  const promises = formRefs.map((formRef) => submitForm(formRef, onlyDirty));

  return Promise.all(promises).then(() => {
    const invalidFormRef = formRefs.find((formRef) => {
      const { current: form } = formRef;
      if (!form || !form.isValid) return form;
      return null;
    });
    if (invalidFormRef) {
      return Promise.reject(invalidFormRef);
    }
  });
};
