import React, { memo } from 'react';
import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import { Field } from 'formik';
import { useTranslation } from 'i18n';
import { makeEntitiesLoader } from 'hooks/makeSimpleLoader';

import { MarketsActions } from 'store/actions';
import { MarketsSelectors } from 'store/selectors';

import { FieldToTextField } from 'components/common/FormField';

export interface MarketsFieldProps extends Omit<TextFieldProps, 'select' | 'value' | 'onChange' | 'children'> {
  onlyLocal?: boolean;
}

const params = {
  page_size: 100,
};

const useCitiesState = makeEntitiesLoader(MarketsActions.getMarkets, MarketsSelectors.marketsState, false);

const MarketsField: React.FC<React.PropsWithChildren<MarketsFieldProps>> = (props) => {
  const [t] = useTranslation();
  const { placeholder, disabled, SelectProps, onlyLocal } = props;
  const marketsState = useCitiesState(() => params, []);

  return (
    <Field
      {...props}
      select
      disabled={disabled || marketsState.isFetching}
      SelectProps={{
        ...SelectProps,
        ...(placeholder && {
          displayEmpty: true,
        }),
      }}
      component={FieldToTextField}
      TextField={TextField}
    >
      {placeholder && (
        <MenuItem disabled value="">
          {placeholder}
        </MenuItem>
      )}
      {marketsState.entities.map((market) =>
        onlyLocal ? (
          market.is_local && (
            <MenuItem key={market.id} value={market.id}>
              {market.title}
            </MenuItem>
          )
        ) : (
          <MenuItem key={market.id} value={market.id}>
            {market.title}
          </MenuItem>
        ),
      )}
      <MenuItem value={0}>{t<string>('common:general.other')}</MenuItem>
    </Field>
  );
};

export default memo(MarketsField);
