import * as Yup from 'yup';
import withFormSubmission, { MergedProps } from 'components/common/withFormSubmission';
import { phoneSchema } from 'utils/validation';

export interface IValues {
  phone: string;
}

export interface IProps {
  isProduction?: boolean;
  user: any;
  onSubmit: (values: IValues) => void;
  error?: any;
}

export type FormComponentProps = MergedProps<IProps, IValues>;

const withPhoneFieldForm = withFormSubmission<IProps, IValues>({
  validationSchema: (props: IProps) =>
    Yup.object({
      phone:
        props?.isProduction && !props.user?.email?.startsWith('nastya.shevchenko')
          ? phoneSchema().required()
          : Yup.string().required(),
    }),
  mapPropsToValues: ({ user }) => ({
    phone: user?.phone ? user?.phone.split('+').slice(-1)[0] : '',
  }),
  handleSubmit: (values, { props: { onSubmit } }) => {
    onSubmit({
      phone: values.phone && !values.phone.startsWith('+') ? `+${values.phone}` : values.phone,
    });
  },
});

export default withPhoneFieldForm;
