import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import { ParsedUrlQuery, ParsedUrlQueryInput } from 'querystring';
import { parseRelativeUrl } from 'next/dist/shared/lib/router/utils/parse-relative-url';
import { urlQueryToSearchParams } from 'next/dist/shared/lib/router/utils/querystring';
import { getFilteredQueryParams } from './useQueryParams';

// transitions options allows change path of the current page without rerunning data fetching methods
const options = {
  shallow: true,
};

/**
 * A utility function for getting object of hash params to prevent query with an empty string
 *
 *  @param hash The hash string
 *  @returns The object of hash params
 */
const getObjectOfHash = (hash: string) => {
  if (!hash) return {};
  return hash
    .substr(1)
    .split('&')
    .reduce((obj, item) => {
      const arrayOfDate = item.split('=');
      const [key, data] = arrayOfDate;
      obj[key] = data;
      return obj;
    }, {} as { [key: string]: string });
};

/**
 * A hook to access the current parsed hash string and function that will be used to set hash string
 *  @returns The parsed hash string, and a function to set it
 */
export const useHashParams = () => {
  const router = useRouter();

  const asPathWithOutQuery = useMemo(() => {
    const { query } = parseRelativeUrl(router.asPath);
    const queryString = urlQueryToSearchParams(query as ParsedUrlQuery).toString();
    return router.asPath.replace(`?${queryString}`, '');
  }, [router.asPath]);

  const hashParams = useMemo(() => {
    const { hash } = parseRelativeUrl(asPathWithOutQuery);
    return getObjectOfHash(hash);
  }, [asPathWithOutQuery]);

  const setHashParams = useCallback(
    (hashParams: ParsedUrlQueryInput) => {
      const { pathname, query } = parseRelativeUrl(router.asPath);
      const filteredHashParams = getFilteredQueryParams(hashParams);
      const hashString = urlQueryToSearchParams(filteredHashParams as ParsedUrlQuery).toString();
      const queryString = urlQueryToSearchParams(query as ParsedUrlQuery).toString();
      let as = String(pathname).replace('/fr/', '/');
      if (queryString) as = `${as}?${queryString}`;
      if (hashString) as = `${as}#${hashString}`;
      if (!hashString && !queryString) as = pathname;

      return router.replace(router.pathname, as, options);
    },
    [router],
  );

  return [hashParams, setHashParams] as const;
};
