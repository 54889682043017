import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useCallback, useEffect } from 'react';
import { isFetchingStates } from 'store/utils';
import { AdminZoomEventsActions } from 'store/actions';
import { AdminZoomEventsSelectors, AuthSelectors } from 'store/selectors';

const stateSelector = createStructuredSelector({
  meetingsState: AdminZoomEventsSelectors.adminZoomMeetingsState,
  webinarsState: AdminZoomEventsSelectors.adminZoomWebinarsState,
  hasZoomConnected: AuthSelectors.hasZoomConnected,
});

const useZoomPlatform = () => {
  const dispatch = useDispatch();
  const { meetingsState, webinarsState, hasZoomConnected } = useSelector(stateSelector);
  const { entities: meetings } = meetingsState;
  const { entities: webinars } = webinarsState;

  const isFetching = isFetchingStates(meetingsState, webinarsState);
  const shouldReload = hasZoomConnected && meetings.length === 0 && webinars.length === 0;

  const reloadZoomEvents = useCallback(() => {
    dispatch(AdminZoomEventsActions.getAdminZoomEvents());
  }, [dispatch]);

  useEffect(() => {
    if (shouldReload) {
      reloadZoomEvents();
    }
  }, [reloadZoomEvents, shouldReload]);

  return {
    reloadZoomEvents,
    isFetching,
    meetings,
    webinars,
  };
};

export default useZoomPlatform;
