import { useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AdminOrganizationsActions } from 'store/actions';
import { AdminOrganizationsSelectors } from 'store/selectors';
import { createStructuredSelector } from 'reselect';

const useStripeAuthorization = (organizationId) => {
  const dispatch = useDispatch();

  const stateSelector = useMemo(() => {
    const selector = createStructuredSelector({
      organization: AdminOrganizationsSelectors.adminOrganizationById,
      isAuthorizing: AdminOrganizationsSelectors.isAuthorizing,
      dashboardLink: AdminOrganizationsSelectors.stripeDashboardLinkState,
    });
    return (state) => selector(state, organizationId);
  }, [organizationId]);

  const state = useSelector(stateSelector);

  useEffect(() => {
    dispatch(AdminOrganizationsActions.getStripeDashboardLink.request({ id: organizationId }));
  }, [dispatch, organizationId]);

  const expressDashboardLink = state.dashboardLink.entity && state.dashboardLink.entity.express_dashboard_link;
  const isDashboardLinkFetching = state.dashboardLink.isFetching;
  const isConnected = expressDashboardLink && expressDashboardLink.length !== 0;

  const onRequestAuthorization = useCallback(() => {
    dispatch(AdminOrganizationsActions.requestOauthAuthorization.request({ params: { organizationId } }));
  }, [dispatch, organizationId]);

  return {
    ...state,
    isConnected,
    isDashboardLinkFetching,
    expressDashboardLink,
    onRequestAuthorization,
  };
};

export default useStripeAuthorization;
