import React from 'react';
import { Button, ButtonProps, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface IProps<C extends React.ElementType> {
  label: string;
  ButtonProps: ButtonProps<C>;
}

const useStyles = makeStyles()(() => ({
  label: {
    fontSize: 11,
    lineHeight: '160%',
    textAlign: 'center',
  },
}));

const AuthSwitch = <C extends React.ElementType>(props: IProps<C>) => {
  const { label, ButtonProps, ...rest } = props;
  const { classes } = useStyles();

  return (
    <div {...rest}>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} md="auto">
          <Typography color="textSecondary" className={classes.label}>
            {label}
          </Typography>
        </Grid>
        <Grid item md="auto">
          <Button variant="outlined" color="primary" size="small" {...ButtonProps} />
        </Grid>
      </Grid>
    </div>
  );
};

export default AuthSwitch;
