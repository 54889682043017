import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useCallback } from 'react';
import { ZoomActions } from 'store/actions';
import { AuthSelectors, ZoomSelectors } from 'store/selectors';

const stateSelector = createStructuredSelector({
  isAuthorizing: ZoomSelectors.isAuthorizing,
  isDeauthorizing: ZoomSelectors.isDeauthorizing,
  zoomOauthData: AuthSelectors.zoomOauthData,
  hasZoomConnected: AuthSelectors.hasZoomConnected,
});

const useZoomAuthorization = () => {
  const dispatch = useDispatch();
  const state = useSelector(stateSelector);

  const onRequestAuthorization = useCallback(() => {
    dispatch(ZoomActions.requestOauthAuthorization.request());
  }, [dispatch]);

  const onRemoveAuthorization = useCallback(() => {
    dispatch(ZoomActions.removeOauthAuthorization.request());
  }, [dispatch]);

  return {
    ...state,
    onRequestAuthorization,
    onRemoveAuthorization,
  };
};

export default useZoomAuthorization;
