import React from 'react';
import PropTypes from 'prop-types';
import { fieldToRadioGroup } from 'formik-mui';

function FieldToRadioGroup({ RadioGroup, ...props }) {
  const fieldProps = fieldToRadioGroup(props);
  if (!fieldProps.value) fieldProps.value = '';
  return <RadioGroup {...fieldProps} />;
}

FieldToRadioGroup.propTypes = {
  RadioGroup: PropTypes.elementType,
};

export default FieldToRadioGroup;
