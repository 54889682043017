import React, { forwardRef } from 'react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { IconButton, InputAdornment, TextField } from '@mui/material';

const DateTextField = forwardRef(
  // eslint-disable-next-line react/prop-types
  ({ value, shrink, inputProps, onOpen, onClick, disabled, ...props }, ref) => (
    <TextField
      {...props}
      disabled={disabled}
      ref={ref}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        onClick: disabled ? undefined : onClick,
        inputProps: {
          ...inputProps,
          value,
          readOnly: true,
        },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton edge="end" color="primary" onClick={onOpen} disabled={disabled} size="large">
              <CalendarTodayIcon color="primary" fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
      }}
      sx={{
        '& legend': { display: 'none' },
        '& .MuiOutlinedInput-notchedOutline': {
          top: '0 !important',
        },
      }}
    />
  ),
);

export default DateTextField;
