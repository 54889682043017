import React, { useCallback, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';

const useFormActions = () => {
  const { dirty, isValid, isValidating, isSubmitting, submitForm, resetForm } = useFormikContext();
  const [edit, setEdit] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSaveClick: React.MouseEventHandler = useCallback(
    (event) => {
      if (dirty && !isSubmitting) {
        event.preventDefault();
        submitForm().then(() => {
          setSubmitted(true);
        });
      }
    },
    [dirty, submitForm, isSubmitting],
  );

  useEffect(() => {
    if (!edit && dirty) {
      resetForm();
    }
  }, [edit, dirty, resetForm]);

  useEffect(() => {
    if (submitted && edit && !isValidating && !isSubmitting) {
      setSubmitted(false);
      if (isValid) {
        setEdit(false);
      }
    }
  }, [submitted, edit, isValid, isValidating, isSubmitting]);

  return [edit, setEdit, handleSaveClick] as const;
};

export default useFormActions;
