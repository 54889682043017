import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle, Grid, IconButton, Theme, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface IProps {
  className?: string;
  padding?: string;
  dense?: boolean;
  subtitle?: string;
  children?: React.ReactNode;
  onClose?: (e: React.MouseEvent) => void;
  closeButtonColor?:
    | 'inherit'
    | 'disabled'
    | 'action'
    | 'success'
    | 'error'
    | 'warning'
    | 'info'
    | 'primary'
    | 'secondary';
  textTitleColor?:
    | 'inherit'
    | 'disabled'
    | 'action'
    | 'success'
    | 'error'
    | 'warning'
    | 'info'
    | 'primary'
    | 'secondary';
  variant?: string;
  variantMobile?: string;
  uppercase?: boolean;
}

interface StyleProps {
  padding?: string;
}

const useStyles = makeStyles<StyleProps>()((theme, params) => ({
  withChildren: {
    padding: params?.padding || theme.spacing(4),
  },
  textUppercase: {
    textTransform: 'uppercase',
  },
  descriptionText: {
    fontWeight: 500,
    marginTop: theme.spacing(1),
  },
}));

const CustomDialogTitle: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const {
    className,
    padding,
    subtitle,
    onClose,
    children,
    dense,
    closeButtonColor = 'primary',
    textTitleColor = 'primary',
    variant = 'body2',
    variantMobile = 'h5',
    uppercase = true,
  } = props;
  const { classes, cx } = useStyles({ padding });
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  return (
    <DialogTitle className={cx({ [classes.withChildren]: children && !dense }, className)}>
      {isMobile ? (
        <Grid container justifyContent="space-between" alignItems="center">
          {onClose && (
            <Grid item xs={1}>
              <IconButton onClick={onClose} edge="start" size="large">
                <CloseIcon color={closeButtonColor} />
              </IconButton>
            </Grid>
          )}
          {children && (
            <>
              <Grid item xs>
                <Typography
                  className={cx({ [classes.textUppercase]: uppercase })}
                  align="center"
                  color={textTitleColor}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  variant={variantMobile}
                >
                  {children}
                </Typography>
              </Grid>
              <Grid item xs={1} />
            </>
          )}
        </Grid>
      ) : (
        <Grid container justifyContent="flex-end">
          {children && (
            <Grid item xs>
              <Typography
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                variant={variant}
              >
                {children}
              </Typography>
              {subtitle && (
                <Typography className={classes.descriptionText} variant="body2" color="textSecondary">
                  {subtitle}
                </Typography>
              )}
            </Grid>
          )}
          {onClose && (
            <Grid item>
              <IconButton size="small" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      )}
    </DialogTitle>
  );
};

export default CustomDialogTitle;
