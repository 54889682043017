import { AuthActions } from 'store/actions';
import makeLoginProvider from './makeLoginProvider';

const useMagicLinkAuthProvider = makeLoginProvider({
  requiredQueryKeys: ['magic_link_token'] as const,
  optionalQueryKeys: ['code', 'confirm'] as const,
  actionCreator: ({ magic_link_token, code, confirm }) =>
    AuthActions.loginMagicLink.request({
      params: {
        magic_link_token,
        code,
        ...(confirm === String(true) && {
          confirm,
        }),
      },
    }),
});

export default useMagicLinkAuthProvider;
