import React, { useEffect, useState } from 'react';
import { Field, Form } from 'formik';
import { useTranslation } from 'i18n';
import { FieldToTextField } from 'components/common/FormField';
import ReactInputVerificationCode from 'react-input-verification-code';
import { makeStyles } from 'tss-react/mui';
import { Grid, Link, Typography } from '@mui/material';
import { findErrorMessage } from 'store/utils';
import withVerificationFieldForm, { FormComponentProps } from './withVerificationFieldForm';

const useStyles = makeStyles()((theme) => ({
  VerificationCode: {
    marginBottom: 8,
    '.ReactInputVerificationCode__container': {
      width: 275,
    },
    '.ReactInputVerificationCode__item': {
      borderRadius: 8,
      width: 40,
      height: 56,
      lineHeight: '60px',
    },
  },
  error: {
    textAlign: 'right',
    '.ReactInputVerificationCode__item': {
      boxShadow: 'inset 0 0 0 1px #E85045',
    },
  },
  formFieldWrapper: {
    '&&&': {
      maxWidth: 284,
    },
    '&&& .MuiFormLabel-root': {
      color: '#000',
    },
  },
  linkButton: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    cursor: 'pointer',
  },
  continueButton: {
    [theme.breakpoints.down('md')]: {
      paddingBottom: 16,
    },
  },
}));

const VerificationCodeFieldForm: React.FC<React.PropsWithChildren<FormComponentProps>> = (props) => {
  const { onResetPhone, onResend, error } = props;
  const [t] = useTranslation('user');
  const { classes, cx } = useStyles();
  const [errorText, setErrorText] = useState('');
  const [isAnotherCode, setIsAnotherCode] = useState(false);

  useEffect(() => {
    if (error) {
      try {
        const parsedError = JSON.parse(error.response.responseText);
        setErrorText(findErrorMessage(parsedError));
      } catch (e) {}
    } else setErrorText('');
  }, [error]);

  const handleResendCode = () => {
    setIsAnotherCode(true);
    onResend();
  };

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Grid container paddingTop={3} justifyContent="space-between" style={{ height: '100%' }} component={Form}>
      <Grid item xs={12} className={classes.formFieldWrapper}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div className={cx(classes.VerificationCode, { [classes.error]: !!error })}>
              <Grid container justifyContent="space-between" style={{ fontSize: 11 }}>
                <Grid item style={{ alignContent: 'end' }}>
                  <Typography variant="caption" fontWeight={600} lineHeight={1.375} color="textPrimary">
                    Verification Code
                  </Typography>
                </Grid>
                {errorText && (
                  <Grid item xs style={{ textAlign: 'right' }}>
                    <Typography variant="caption" fontWeight={600} lineHeight={1.375} color="secondary">
                      {errorText}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Field
                label="Verification Code"
                fullWidth
                name="code"
                component={FieldToTextField}
                country="US"
                length={6}
                placeholder=""
                autoFocus
                TextField={ReactInputVerificationCode}
                helperText={errorText}
              />
            </div>
          </Grid>
          {isAnotherCode && (
            <Grid item xs={12}>
              <Typography component="span" style={{ fontWeight: 500 }} variant="body2" color="textSecondary">
                {t<string>('jLivePassport.phoneVerificationModal.code.anotherCodeSend')}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Link className={classes.linkButton} underline="none" onClick={handleResendCode}>
              <Typography component="span" style={{ fontWeight: 500 }} variant="body2" textTransform="uppercase">
                {t(`jLivePassport.phoneVerificationModal.code.${isAnotherCode ? 'sendAgain' : 'resendCode'}`)}
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Link className={classes.linkButton} underline="none" onClick={onResetPhone}>
              <Typography component="span" style={{ fontWeight: 500 }} variant="body2" textTransform="uppercase">
                {t<string>('jLivePassport.phoneVerificationModal.code.setDifferentPhoneNumber')}
              </Typography>
            </Link>
          </Grid>
        </Grid>
        <Grid item xs={12} />
      </Grid>
    </Grid>
  );
};

export default withVerificationFieldForm(VerificationCodeFieldForm);
