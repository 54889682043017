import * as Yup from 'yup';
import withFormSubmission, { MergedProps } from 'components/common/withFormSubmission';

export interface MfaCodeFormValues {
  code: string;
}

export interface MfaCodeFormProps {
  onSubmit: (values: MfaCodeFormValues) => void;
}

export type FormComponentProps = MergedProps<MfaCodeFormProps, MfaCodeFormValues>;

const withMfaCodeForm = withFormSubmission<MfaCodeFormProps, MfaCodeFormValues>({
  validationSchema: Yup.object({
    code: Yup.string().length(6),
  }),
  mapPropsToValues: () => ({
    code: '',
  }),
  handleSubmit: (values, { props: { onSubmit } }) => {
    onSubmit(values);
  },
});

export default withMfaCodeForm;
