import React, { useEffect } from 'react';
import { Box, Grid, InputAdornment, Link, Typography } from '@mui/material';
import { Field, Form } from 'formik';
import { useTranslation } from 'i18n';
import { FieldToTextField } from 'components/common/FormField';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/common/Button';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import MagicLinkIcon from 'components/svg/MagicLinkIcon.svg';
import GoogleIcon from 'components/svg/GoogleIcon.svg';
import withLoginForm, { FormComponentProps } from './withLoginForm';
import { AuthSelectors } from '../../../store/selectors';
import { AuthActions } from '../../../store/actions';
import CustomTextField from '../../common/CustomTextField';
import useMedian from '../../../hooks/useMedian';

const useStyles = makeStyles()((theme) => ({
  orLoginWithTitle: {
    background: theme.palette.background.paper,
    fontWeight: 500,
    fontSize: 13,
    lineHeight: 1.2,
    color: theme.palette.text.secondary,
  },
  singInButton: {
    textTransform: 'uppercase',
    border: '1px solid #CAD1DE',
    borderRadius: 32,
    height: 40,
    width: 264,
  },
  magicLinkButton: {
    fontWeight: 500,
    border: '1px solid #CAD1DE',
    color: theme.palette.text.secondary,
    height: 40,
    width: 264,
  },
  googleButton: {
    fontWeight: 500,
    border: '1px solid #CAD1DE',
    color: theme.palette.text.secondary,
    height: 40,
    width: 264,
  },
  googleButtonTitle: {
    paddingLeft: 10,
  },
  field: {
    width: 264,
  },
  forgotPasswordWrapper: {
    width: 264,
    textAlign: 'right',
  },
  forgotPassword: {
    cursor: 'pointer',
    fontWeight: 600,
  },
}));

const LoginForm: React.FC<React.PropsWithChildren<FormComponentProps>> = (props) => {
  const { isSubmitting, onMlSwitch, onForgotPassword, errorMessage } = props;
  const [t] = useTranslation('public');
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = React.useState(false);
  const { isMedian } = useMedian();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const linkState = useSelector(AuthSelectors.socialLinksState);

  useEffect(() => {
    if (!linkState.result) {
      dispatch(AuthActions.socialLinks.reset());
      dispatch(AuthActions.socialLinks.request());
    }
  }, [dispatch, linkState?.result]);

  const onGoogleButtonClick = () => {
    window.location.href = linkState?.result?.auth_url;
  };

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Grid container spacing={1} component={Form}>
      <Grid item xs={12}>
        <Box mt={1} textAlign="center">
          <Field
            className={classes.field}
            variant="outlined"
            name="email"
            autoComplete="email"
            placeholder={t<string>('common:field.placeholder.emailShort')}
            component={FieldToTextField}
            error={errorMessage}
            TextField={CustomTextField}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mt={1} textAlign="center">
          <Field
            className={classes.field}
            variant="outlined"
            name="password"
            placeholder={t<string>('common:field.placeholder.passwordShort')}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="small"
                  >
                    {showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={errorMessage}
            helperText={errorMessage}
            component={FieldToTextField}
            TextField={CustomTextField}
          />
          <div className={classes.forgotPasswordWrapper}>
            <Link className={classes.forgotPassword} variant="caption" underline="none" onClick={onForgotPassword}>
              {t<string>('auth.loginForm.forgotPassword')}
            </Link>
          </div>
        </Box>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 0 }}>
        <Box mt={1} textAlign="center">
          <Button type="submit" className={classes.singInButton} variant="contained">
            {t<string>('auth.loginForm.singIn')}
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mt={1} textAlign="center" className={classes.orLoginWithTitle}>
          {t<string>('auth.loginForm.or')}
        </Box>
      </Grid>
      {!isMedian && (
        <Grid item xs={12}>
          <Box mt={1} textAlign="center">
            <Button
              variant="outlined"
              onClick={onGoogleButtonClick}
              disabled={isSubmitting || !linkState?.result}
              className={classes.googleButton}
              startIcon={<GoogleIcon />}
            >
              <Typography
                variant="body2"
                className={classes.googleButtonTitle}
                style={{ fontWeight: 500, textTransform: 'initial' }}
              >
                {t<string>('auth.loginForm.loginGoogle')}
              </Typography>
            </Button>
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box mt={1} textAlign="center">
          <Button
            className={classes.magicLinkButton}
            onClick={onMlSwitch}
            variant="outlined"
            startIcon={<MagicLinkIcon />}
          >
            <Typography variant="body2" style={{ fontWeight: 500, textTransform: 'initial' }}>
              {t<string>('auth.loginForm.loginMagicLink')}
            </Typography>
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default withLoginForm(LoginForm);
