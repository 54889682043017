import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import { ParsedUrlQuery, ParsedUrlQueryInput } from 'querystring';
import { parseRelativeUrl } from 'next/dist/shared/lib/router/utils/parse-relative-url';
import { urlQueryToSearchParams } from 'next/dist/shared/lib/router/utils/querystring';

// transitions options allows change path of the current page without rerunning data fetching methods
const options = {
  shallow: true,
};

/**
 * A utility function for getting filtered query params to prevent query with an empty string
 *
 *  @param queryParams The query input
 *  @returns The filtered query params
 */
export const getFilteredQueryParams = (queryParams: ParsedUrlQueryInput): ParsedUrlQueryInput =>
  Object.keys(queryParams).reduce((filteredQueryParams, key) => {
    const value = queryParams[key];
    if (value !== null && value !== undefined && value !== '') filteredQueryParams[key] = value;
    return filteredQueryParams;
  }, {} as ParsedUrlQueryInput);

/**
 * A hook to access the current parsed query string and function that will be used to set query string
 *  @returns The parsed query string, and a function to set it
 */
export const useQueryParams = () => {
  const router = useRouter();

  const asPathWithOutHash = useMemo(() => {
    const { hash } = parseRelativeUrl(router.asPath);
    return router.asPath.replace(hash, '');
  }, [router.asPath]);

  const queryParams = useMemo(() => {
    const { query } = parseRelativeUrl(asPathWithOutHash);
    return query;
  }, [asPathWithOutHash]);

  const setQueryParams = useCallback(
    (queryParams: ParsedUrlQueryInput) => {
      const { pathname, hash } = parseRelativeUrl(router.asPath);
      const filteredQueryParams = getFilteredQueryParams(queryParams);
      const queryString = urlQueryToSearchParams(filteredQueryParams as ParsedUrlQuery).toString();
      let as = String(pathname).replace('/fr/', '/');
      if (queryString) as = `${as}?${queryString}`;
      if (hash) as = `${as}${hash}`;
      if (!hash && !queryString) as = pathname;

      return router.push(router.pathname, as, options);
    },
    [router],
  );

  return [queryParams, setQueryParams] as const;
};
