import * as Yup from 'yup';
import withFormSubmission, { MergedProps } from 'components/common/withFormSubmission';
import { minAgeSchema, postalCodeSchema } from 'utils/validation';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { GENDER_OPTIONS } from '../../types/options';

export interface IValues {
  postal_code: string;
  birth_date?: Date | string;
  gender: string;
}

export interface IProps {
  user: any;
  onSubmit: (values: IValues) => void;
}

export type FormComponentProps = MergedProps<IProps, IValues>;

const withUpdateProfileForm = withFormSubmission<IProps, IValues>({
  validationSchema: () =>
    Yup.object({
      postal_code: postalCodeSchema().required(),
      birth_date: minAgeSchema(13, 'Age must be at least 13').required(),
      gender: Yup.mixed().oneOf(GENDER_OPTIONS).required(),
    }),
  mapPropsToValues: ({ user }) => ({
    postal_code: user?.address?.postal_code || '',
    birth_date: (user.birth_date && utcToZonedTime(parseISO(new Date(user.birth_date).toISOString()), 'UTC')) || null,
    gender: user?.gender || '',
  }),
  handleSubmit: (values, { props: { onSubmit } }) => {
    const { birth_date, postal_code, ...rest } = values as any;
    rest.birth_date = format(birth_date, 'yyyy-MM-dd');
    rest.address = {};
    rest.address.postal_code = postal_code;
    onSubmit(rest);
  },
});

export default withUpdateProfileForm;
