import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Image from 'next/legacy/image';

interface IProps {
  src: string;
  alt?: string;
  children?: React.ReactNode;
}

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    '& > :not(img)': {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
  },
  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

const ImageTile: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { src, alt, children } = props;
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <Image className={classes.image} src={src} alt={alt} layout="fill" />
      {children}
    </div>
  );
};

export default ImageTile;
