import React, { useRef } from 'react';
import { useTranslation } from 'i18n';
import AuthLayout from './AuthLayout';
import TextLeftContent from './TextLeftContent';
import SetPasswordForm from './SetPasswordForm';
import BottomNavigation from './BottomNavigation';
import { submitForm } from '../../utils/form';

export interface InterestViewProps {
  onSubmit: (values: any) => void;
  onClose: () => void;
  isPending: boolean;
}

const UserInfoView: React.FC<React.PropsWithChildren<InterestViewProps>> = (props) => {
  const { onSubmit, onClose, isPending } = props;
  const [t] = useTranslation('public');
  const formRef = useRef(null);

  const handleSubmitForm = () => submitForm(formRef, false);

  return (
    <AuthLayout
      content={
        <SetPasswordForm
          innerRef={formRef}
          resetAfterSending
          isSending={isPending}
          onSubmit={onSubmit}
          onClose={onClose}
          t={t}
        />
      }
      left={
        <TextLeftContent
          title={t<string>('auth.setPasswordView.title')}
          description={t<string>('auth.setPasswordView.description')}
        />
      }
      actions={<BottomNavigation step={3} onClick={handleSubmitForm} onClose={onClose} />}
    />
  );
};

export default UserInfoView;
