import React, { useRef } from 'react';
import { useTranslation } from 'i18n';
import AuthLayout from './AuthLayout';
import TextLeftContent from './TextLeftContent';
import AuthContent from './AuthContent';
import UserInfoForm from './UserInfoForm';
import { UserOptionType } from '../userProfile/UserDemographicsSection/withUserDemographicsSectionForm';
import BottomNavigation from './BottomNavigation';
import { submitForm } from '../../utils/form';

export interface InterestViewProps {
  user: any;
  jewishDescendants: UserOptionType[];
  onSubmit: (values: any) => void;
}

const UserInfoView: React.FC<React.PropsWithChildren<InterestViewProps>> = (props) => {
  const { jewishDescendants, user, onSubmit } = props;
  const [t] = useTranslation('public');
  const formRef = useRef(null);

  const handleSubmitForm = () => submitForm(formRef, false);

  return (
    <AuthLayout
      content={
        <AuthContent
          form={
            <div style={{ paddingTop: 40 }}>
              <UserInfoForm
                innerRef={formRef}
                t={t}
                user={user}
                jewishDescendants={jewishDescendants}
                onSubmit={onSubmit}
              />
            </div>
          }
        />
      }
      left={
        <TextLeftContent
          title={t<string>('auth.userInfoView.title')}
          description={t<string>('auth.userInfoView.description')}
        />
      }
      actions={<BottomNavigation step={2} onClick={handleSubmitForm} />}
    />
  );
};

export default UserInfoView;
