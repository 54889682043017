import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthActions } from 'store/actions';
import { AuthSelectors } from 'store/selectors';
import { NextRouter, useRouter } from 'next/router';
import JoinView, { JoinViewProps } from './JoinView';

export type JoinViewContainerProps = Omit<
  JoinViewProps,
  'user' | 'isSending' | 'isSuccessfullySubmitted' | 'isAuthenticated' | 'submittedEmail' | 'onSubmit'
> & {
  onNext: () => void;
  showSwitchButton: boolean;
};

const getRedirectTo = (router: NextRouter): string => {
  const { pathname, asPath, query } = router;
  const { redirect_to } = query;

  if (typeof redirect_to === 'string') return redirect_to;
  if (pathname !== '/') return asPath;
  return null;
};

const JoinViewContainer: React.FC<React.PropsWithChildren<JoinViewContainerProps>> = (props) => {
  const { onNext, ...rest } = props;
  const router = useRouter();
  const dispatch = useDispatch();
  const redirect_to = getRedirectTo(router);
  const currentUser = useSelector(AuthSelectors.currentUser);
  const joinState = useSelector(AuthSelectors.joinState);
  const isAuthenticated = useSelector(AuthSelectors.isAuthenticated);
  const [submittedEmail, setSubmittedEmail] = useState('');

  const isSuccessfullySubmitted = joinState.isFulfilled && !joinState.error;
  const isSending = joinState.isPending;

  const handleSubmit = useCallback(
    (values: any) => {
      setSubmittedEmail(values.email);
      dispatch(
        AuthActions.join.request({
          params: {
            ...values,
            ...(redirect_to && {
              redirect_to,
            }),
          },
        }),
      );
    },
    [dispatch, redirect_to],
  );

  useEffect(() => {
    if (isSuccessfullySubmitted && isAuthenticated) onNext();
  }, [isSuccessfullySubmitted, isAuthenticated, onNext]);

  useEffect(() => {
    if (joinState.isFulfilled) {
      return () => {
        dispatch(AuthActions.join.reset());
      };
    }
  }, [dispatch, joinState.isFulfilled]);

  return (
    <JoinView
      {...rest}
      user={currentUser}
      isSending={isSending}
      isSuccessfullySubmitted={isSuccessfullySubmitted}
      isAuthenticated={isAuthenticated}
      submittedEmail={submittedEmail}
      onSubmit={handleSubmit}
    />
  );
};

export default memo(JoinViewContainer);
