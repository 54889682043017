import React, { memo, useEffect, useState } from 'react';
import { Grid, Theme, useMediaQuery } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import nodeMap from 'utils/nodeMap';
import Image from 'next/legacy/image';

interface IProps {
  images?: React.ReactNode;
  left?: React.ReactNode;
  switcher?: React.ReactNode;
  content?: React.ReactNode;
  actions?: React.ReactNode;
  width?: number;
  leftMinWidth?: number;
  rightWrapperNoPT?: boolean;
}

interface StyleProps {
  width?: number;
  leftMinWidth?: number;
  showMoreBottom?: number;
}

const useStyles = makeStyles<StyleProps>()((theme, params) => ({
  root: {
    ...theme.mixins.scrollBarOverflowYAuto,
    height: '100%',
    ...(params.width && { width: params.width }),
    background: 'white',
    overflowX: 'hidden',
    [theme.breakpoints.up('md')]: {
      maxHeight: 543,
      overflowY: 'hidden',
      borderRadius: 8,
      borderTopLeftRadius: 100,
    },
  },
  leftSidebar: {
    minWidth: 405,
    width: 586,
    ...(params.leftMinWidth && { minWidth: params.leftMinWidth }),
    [theme.breakpoints.up('md')]: {
      maxWidth: 586,
      minHeight: 543,
      overflow: 'hidden',
      borderBottomRightRadius: 100,
    },
  },
  left: {
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-start',
      alignContent: 'flex-start',
    },
  },
  img: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flex: '1 0 50%',
      height: '50%',
    },
  },
  right: {
    [theme.breakpoints.down('md')]: {
      overflow: 'hidden',
    },
    [theme.breakpoints.up('md')]: {
      height: '100%',
      overflowY: 'auto',
    },
    '& > *': {
      padding: theme.spacing(3, 5),
      paddingBottom: 0,
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(5, 2),
        paddingBottom: theme.spacing(5),
        '& > *:nth-of-type(2)': {
          order: -1,
        },
      },
    },
  },
  switcher: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  content: {
    height: '100%',
  },
  rightWrapperNoPT: {
    [theme.breakpoints.down('md')]: {
      paddingTop: '0 !important',
    },
  },
  scrollToViewMore: {
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.90) 50.5%, #FFF 100%)',
    height: 60,
    bottom: params?.showMoreBottom || 32,
    zIndex: 110,
    position: 'sticky',
    transition: 'opacity 1s ease-in-out',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      bottom: params?.showMoreBottom ? params.showMoreBottom - 16 : 32,
      width: '100%',
    },
  },
  scrollToViewMoreImage: {
    display: 'inline-block',
    textAlign: 'center',
    width: 36,
    height: 36,
  },
  scrollToViewMoreTitle: {
    position: 'relative',
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '160%',
    color: '#4E6DF5',
    height: 21,
    textAlign: 'center',
    width: '100%',
  },
  actions: {
    zIndex: 100,
    backgroundColor: '#fff',
    position: 'sticky',
    bottom: 0,
    maxHeight: 96,
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      width: '100%',
      bottom: 0,
      left: 0,
    },
  },
}));

const AuthLayout: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { images, switcher, left, content, actions, width, leftMinWidth, rightWrapperNoPT } = props;
  const { classes, cx } = useStyles({ width, leftMinWidth, showMoreBottom: actions ? 100 : 32 });
  const [hasScroll, setScroll] = useState(false);
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));

  useEffect(() => {
    const rootWrapper = document.getElementById('root-wrapper');
    const rightContentWrapper = document.getElementById('right-content-wrapper');

    setScroll(
      rootWrapper.scrollHeight > rootWrapper.clientHeight ||
        rightContentWrapper.scrollHeight > rightContentWrapper.clientHeight,
    );
  }, [isMobile]);

  return (
    <Grid
      id="root-wrapper"
      onScroll={() => setScroll(false)}
      container
      className={cx(classes.root, { [classes.left]: Boolean(left) })}
    >
      {images && (
        <Grid item xs={12} md className={cx(classes.leftSidebar, classes.img)}>
          {nodeMap(images, (image: React.ReactNode) => (
            <div>{image}</div>
          ))}
        </Grid>
      )}
      {left && (
        <Grid item xs={12} md={4} className={classes.leftSidebar}>
          {left}
        </Grid>
      )}
      <Grid id="right-content-wrapper" item xs={12} md className={classes.right} onScroll={() => setScroll(false)}>
        <Grid
          container
          spacing={1}
          className={cx({ [classes.rightWrapperNoPT]: Boolean(rightWrapperNoPT) })}
          style={{ minHeight: 543 }}
        >
          {switcher && (
            <Grid item xs={12} className={classes.switcher}>
              {switcher}
            </Grid>
          )}
          <Grid item xs={12} className={cx({ [classes.content]: !switcher })}>
            {content}
          </Grid>
          {hasScroll && (
            <Grid id="scroll-for-more" item xs={12} className={classes.scrollToViewMore}>
              <Image
                className={classes.scrollToViewMoreImage}
                src="/static/img/ScrollForMore.png"
                alt=""
                width={36}
                height={36}
              />
              <div className={classes.scrollToViewMoreTitle}>Scroll down</div>
            </Grid>
          )}
          {actions && (
            <Grid item xs={12} className={classes.actions}>
              {actions}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(AuthLayout);
