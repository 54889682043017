import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dialog, DialogProps, Theme, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AuthSelectors, UserSelectors } from 'store/selectors';
import { AuthActions, UserActions } from 'store/actions';
import { UserPassportTypes } from 'api/general/models/UserPassport';
import { useHashParams } from 'hooks';
import PaperComponent from './PaperComponent';
import PhoneVerificationViewContainer from './PhoneVerification/PhoneVerificationViewContainer';
import ProfileUpdatesViewContaier from './ProfileUpdates/ProfileUpdatesViewContaier';
import CloseButton from './CloseButton';

export interface JLivePassportVerificationModalProps extends DialogProps {
  addToHashParams?: boolean;
  variant: 'phone' | 'profileUpdates';
  onSuccess?: () => void;
}

const JLivePassportVerificationModal = (props: JLivePassportVerificationModalProps) => {
  const { addToHashParams, variant: initialVariant = 'phone', onClose, onSuccess, ...other } = props;
  const dispatch = useDispatch();
  const [variant, setVariant] = useState<JLivePassportVerificationModalProps['variant']>(initialVariant);
  const user = useSelector(AuthSelectors.currentUser);
  const userPassportCreatingState = useSelector(UserSelectors.userPassportCreatingState);
  const [, setHashParams] = useHashParams();
  const isSmallMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const onDefaultDialogClose = useCallback(
    (event: any, reason: string) => {
      if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) return;
      dispatch(AuthActions.getVerificationCode.reset());
      dispatch(AuthActions.checkVerificationCode.reset());
      onClose({}, null);
    },
    [dispatch, onClose],
  );

  const handleClose = useCallback(() => {
    dispatch(AuthActions.getVerificationCode.reset());
    dispatch(AuthActions.checkVerificationCode.reset());
    onClose({}, null);
    setVariant('phone');
  }, [dispatch, onClose]);

  useEffect(() => {
    if (userPassportCreatingState.isSuccessfullyCreated) {
      if (addToHashParams) setHashParams({ internalPassportCreated: true });
      if (onSuccess) onSuccess();
      handleClose();
      dispatch(UserActions.createUserPassport.reset());
    }
    return () => {
      dispatch(UserActions.createUserPassport.reset());
    };
  }, [
    addToHashParams,
    dispatch,
    handleClose,
    onSuccess,
    setHashParams,
    userPassportCreatingState.isSuccessfullyCreated,
  ]);

  const noNeedToUpdateProfile = useMemo(
    () => !!(user?.address?.postal_code && user?.birth_date && user?.gender),
    [user],
  );

  const createPassport = useCallback(() => {
    dispatch(UserActions.createUserPassport.request({ entity: { passport_type: UserPassportTypes.INTERNAL } }));
  }, [dispatch]);

  const handleProfileUpdatesSwitch = () => {
    dispatch(UserActions.partialUpdateUser.reset({ id: user.id }));
    if (noNeedToUpdateProfile) {
      createPassport();
    } else setTimeout(() => setVariant('profileUpdates'), 100);
  };

  const views: Record<JLivePassportVerificationModalProps['variant'], React.ReactNode> = {
    phone: (
      <PhoneVerificationViewContainer
        user={user}
        onNextStep={handleProfileUpdatesSwitch}
        stepper={!noNeedToUpdateProfile}
      />
    ),
    profileUpdates: (
      <ProfileUpdatesViewContaier user={user} onNextStep={createPassport} stepper={initialVariant === 'phone'} />
    ),
  };

  return (
    <Dialog
      onClose={onDefaultDialogClose}
      PaperComponent={PaperComponent}
      maxWidth="lg"
      fullScreen={isSmallMobile}
      {...other}
    >
      {isSmallMobile && <CloseButton color="#4E6DF5" left onClose={handleClose} size="medium" />}
      {views[variant]}
      {!isSmallMobile && <CloseButton onClose={handleClose} />}
    </Dialog>
  );
};

export default JLivePassportVerificationModal;
