import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface IProps {
  color?: string;
  onClose?: () => void;
  left?: boolean;
  size?: 'small' | 'medium' | 'large';
}

const useStyles = makeStyles<IProps>()((theme, params) => ({
  root: {
    color: params?.color || 'white',
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(3),
    // height: 0,
    [theme.breakpoints.down('md')]: {
      top: theme.spacing(1),
      ...(params?.left ? { left: theme.spacing(1), right: 'unset' } : { right: theme.spacing(1) }),
    },
  },
}));

const CloseButton: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { color, onClose, left, size = 'small' } = props;
  const { classes } = useStyles(
    { color, left },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      props,
    },
  );
  return (
    <IconButton size={size} className={classes.root} onClick={onClose}>
      <CloseIcon fontSize="inherit" />
    </IconButton>
  );
};

export default CloseButton;
