import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps } from '@mui/material';
import { useTranslation } from 'i18n';
import CustomDialogTitle from 'components/common/CustomDialogTitle';

interface AuthErrorModalProps extends DialogProps {
  error: string;
}

const AuthErrorModal = (props: AuthErrorModalProps) => {
  const { error, onClose, ...other } = props;
  const [t] = useTranslation('public');

  const handleCloseClick: React.MouseEventHandler = (e) => {
    onClose(e, null);
  };

  return (
    <Dialog onClose={onClose} fullWidth maxWidth="sm" {...other}>
      <CustomDialogTitle>{t<string>('auth.authErrorModal.title')}</CustomDialogTitle>
      <DialogContent>
        <DialogContentText>{error}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseClick} color="secondary">
          {t<string>('common:button.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AuthErrorModal;
