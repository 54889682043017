import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { AuthActions } from 'store/actions';
import { AuthSelectors } from 'store/selectors';
import AuthModal from 'components/auth/AuthModal';
import useModal from './useModal';

const stateSelector = createStructuredSelector({
  isAuthenticated: AuthSelectors.isAuthenticated,
  isHardUser: AuthSelectors.isHardUser,
  isAdmin: AuthSelectors.isAdmin,
  isSuperAdmin: AuthSelectors.isSuperAdmin,
  currentUser: AuthSelectors.currentUser,
});

const useAuthProps = () => {
  const dispatch = useDispatch();
  const [showAuthModal] = useModal(AuthModal);
  const { isAuthenticated, isHardUser, isAdmin, isSuperAdmin, currentUser } = useSelector(stateSelector);

  const onLogin = useCallback(() => {
    showAuthModal();
  }, [showAuthModal]);

  const onLogout = useCallback(() => {
    sessionStorage.removeItem('marketInitiated');
    dispatch(AuthActions.logout());
    sessionStorage.setItem('shownNudgeToLoginPopover', String(true));
  }, [dispatch]);

  return {
    isAuthenticated,
    isHardUser,
    isAdmin,
    isSuperAdmin,
    currentUser,
    onLogin,
    onLogout,
  };
};

export default useAuthProps;
