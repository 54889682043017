import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Action } from 'redux';
import { useRouter } from 'next/router';

const isValidQueryParam = (param: string | string[]) => typeof param === 'string' && param.length > 0;

const makeLoginProvider = <
  RequiredQueryKeys extends readonly string[],
  OptionalQueryKeys extends readonly string[] = never,
>(args: {
  requiredQueryKeys: RequiredQueryKeys;
  optionalQueryKeys?: OptionalQueryKeys;
  actionCreator: (
    query: Record<
      [OptionalQueryKeys] extends [never]
        ? RequiredQueryKeys[number]
        : RequiredQueryKeys[number] | OptionalQueryKeys[number],
      string
    >,
  ) => Action;
}) => {
  const { requiredQueryKeys, optionalQueryKeys = [] as const, actionCreator } = args;
  const queryKeys = requiredQueryKeys.concat(optionalQueryKeys);

  return () => {
    const router = useRouter();
    const dispatch = useDispatch();
    const getQuery = useRef(null);

    const hasRequiredKeys = requiredQueryKeys.every((key) => isValidQueryParam(router.query[key]));

    getQuery.current = () =>
      queryKeys.reduce((query, key) => {
        const param = router.query[key];
        if (isValidQueryParam(param)) query[key] = param as string;
        return query;
      }, {} as Record<string, string>);

    useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { isRedirect, ...restQuery } = router.query; // Destructure to exclude 'isRedirect'

      if ('isRedirect' in router.query) {
        router.replace(
          {
            pathname: router.pathname,
            query: restQuery,
          },
          undefined,
          { shallow: true },
        );
      }
    }, [router]);

    useEffect(() => {
      if (hasRequiredKeys) dispatch(actionCreator(getQuery.current()));
    }, [dispatch, hasRequiredKeys]);
  };
};

export default makeLoginProvider;
