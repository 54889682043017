import { useCallback, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

import { AuthSelectors } from 'store/selectors';
import { AuthActions } from 'store/actions';

import { findErrorMessage } from 'store/utils';

import { useModal } from 'hooks/index';

import { routes } from 'utils/routing';

import AuthErrorModal from 'components/auth/AuthErrorModal';
import MfaCodeModal from 'components/auth/MfaCodeModal/MfaCodeModal';
import LoaderModal from 'components/modals/LoaderModal';
import JLivePassportVerificationModal from '../../components/JLivePassport/JLivePassortVerificationModal';

const AuthModalDynamic = dynamic(() => import('components/auth/AuthModal'), {
  ssr: false,
});

const MFA_REQUIRED = 'mfa_required';

const useAuthFlow = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [showAuthModal] = useModal(AuthModalDynamic);
  const [showErrorModal] = useModal(AuthErrorModal);
  const [showMfaCodeModal] = useModal(MfaCodeModal);
  const [showLoaderModal, closeLoaderModal] = useModal(LoaderModal, false);
  const [showJLivePassportVerificationModal] = useModal(JLivePassportVerificationModal);

  const { isPending, isFulfilled, error } = useSelector(AuthSelectors.loginMagicLinkState);
  const { isFulfilled: loginIsFullFiled, error: loginError } = useSelector(AuthSelectors.loginState);
  const user = useSelector(AuthSelectors.currentUser);
  const isAuthenticated = useSelector(AuthSelectors.isAuthenticated);
  const isHardUser = useSelector(AuthSelectors.isHardUser);
  const isSuccessfully = (isFulfilled && !error) || (loginIsFullFiled && !loginError);
  const isPreCreateUser = useSelector(AuthSelectors.isPreCreateUser);

  const { query, replace } = router;
  const { auth, redirect_to, confirm } = query;

  useEffect(() => {
    if (isPending) {
      showLoaderModal({
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
      });

      return () => {
        closeLoaderModal();
      };
    }
  }, [showLoaderModal, closeLoaderModal, isPending]);

  const onSuccessfullyProceed = useCallback(() => {
    showJLivePassportVerificationModal({
      addToHashParams: true,
      variant: user?.is_phone_verified ? 'profileUpdates' : 'phone',
    });
  }, [showJLivePassportVerificationModal, user?.is_phone_verified]);

  useEffect(() => {
    if (error) {
      const redirect = () => {
        dispatch(AuthActions.loginMagicLink.reset());
        const route = routes.home();
        replace(route.href, null, { shallow: true });
      };

      try {
        const parsedError = JSON.parse(error.response.responseText);
        const isMfaRequiredError = parsedError.code === MFA_REQUIRED;

        if (isMfaRequiredError) {
          showMfaCodeModal();
        } else {
          const displayedError = findErrorMessage(parsedError);
          showErrorModal({
            error: displayedError,
            onExited: redirect,
            onClose: () => dispatch(AuthActions.loginMagicLink.reset()),
          });
        }
      } catch (e) {}
    }
  }, [showErrorModal, showMfaCodeModal, error, replace, dispatch]);

  useEffect(() => {
    if ((!isAuthenticated && (auth === 'login' || auth === 'join')) || (!isHardUser && auth === 'join')) {
      showAuthModal({
        variant: auth,
      });
    }
  }, [showAuthModal, isAuthenticated, isHardUser, auth]);

  useEffect(() => {
    if (isSuccessfully) {
      dispatch(AuthActions.loginMagicLink.reset());
      const redirect = () => {
        if (typeof redirect_to === 'string' && redirect_to) {
          replace(redirect_to);
        } else {
          const route = routes.home();
          replace(route.href, null, { shallow: true });
        }
      };

      if (!isHardUser) {
        showAuthModal({
          variant: 'join',
          onExited: redirect,
          onSuccessfullyProceed,
        });
      } else if (confirm || isPreCreateUser) {
        showAuthModal({
          variant: 'interest',
          onExited: redirect,
          onSuccessfullyProceed,
        });
      } else {
        redirect();
      }
    }
  }, [
    showAuthModal,
    dispatch,
    replace,
    isHardUser,
    isSuccessfully,
    confirm,
    isPreCreateUser,
    redirect_to,
    showJLivePassportVerificationModal,
    user?.is_phone_verified,
    onSuccessfullyProceed,
  ]);
};

export default useAuthFlow;
