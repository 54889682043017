import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Grid, Typography, Divider, GridWrap } from '@mui/material';

interface IProps {
  action?: React.ReactNode;
  children: React.ReactNode | string;
  className?: string;
  wrap?: GridWrap;
}

const useStyles = makeStyles()(() => ({
  labelStyle: {
    fontWeight: 500,
    textTransform: 'uppercase',
  },
}));

const CustomDivider: React.FC<React.PropsWithChildren<IProps>> = ({ wrap = 'nowrap', action, children, className }) => {
  const { classes, cx } = useStyles();
  const isNoWrap = wrap === 'nowrap';
  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item container spacing={2} alignItems="center" justifyContent="space-between" wrap={wrap}>
        <Grid item xs zeroMinWidth={isNoWrap}>
          {typeof children === 'string' ? (
            <Typography
              className={cx(classes.labelStyle, className)}
              color="textSecondary"
              variant="body2"
              noWrap={isNoWrap}
            >
              {children}
            </Typography>
          ) : (
            children
          )}
        </Grid>
        {action && <Grid item>{action}</Grid>}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default React.memo(CustomDivider);
