import React from 'react';
import { Link, Typography } from '@mui/material';
import { Trans, useTranslation } from 'i18n';
import AuthContent from './AuthContent';

export interface AuthSubmitContentProps {
  passwordText?: boolean;
  email: string;
  variant: 'login' | 'join' | 'resetPassword' | 'phoneVerification';
}

const helpUrl = 'https://help.jlive.app/knowledge-base/magiclinks';

const AuthSubmitContent: React.FC<React.PropsWithChildren<AuthSubmitContentProps>> = (props) => {
  const { passwordText, email, variant, ...rest } = props;
  const [t] = useTranslation('public');

  return (
    <AuthContent
      {...rest}
      title={t(`auth.authSubmitContent.${variant === 'phoneVerification' ? 'phoneVerificationTitle' : 'title'}`)}
      description={
        <>
          {variant === 'phoneVerification' && (
            <Typography variant="caption" color="textSecondary">
              {t<string>('auth.authSubmitContent.phoneVerificationDescription')}
            </Typography>
          )}
          {variant !== 'phoneVerification' && passwordText && (
            <>
              <Trans i18nKey="auth.authSubmitContent.passwordless" ns="public">
                <Link href={helpUrl} target="_blank" underline="none">
                  Read more about passwordless login
                </Link>
              </Trans>
              <br />
              <br />
            </>
          )}
          {variant !== 'phoneVerification' && (
            <Trans i18nKey="auth.authSubmitContent.sent" ns="public" tOptions={{ context: variant }} values={{ email }}>
              <Link href={`mailto:${email}`} underline="none">
                {email}
              </Link>
            </Trans>
          )}
        </>
      }
    />
  );
};

export default AuthSubmitContent;
