import React, { useState } from 'react';
import { Dialog, DialogProps } from '@mui/material';
import { useSelector } from 'react-redux';
import { AuthSelectors } from 'store/selectors';
import PaperComponent from './PaperComponent';
import CloseButton from './CloseButton';
import LoginViewContainer from '../LoginViewContainer';
import JoinViewContainer from '../JoinViewContainer';
import InterestViewContainer from '../InterestViewContainer';
import UserInfoContainer from '../UserInfoContainer';
import MagicLinkLoginViewContainer from '../MagicLinkLoginViewContainer';
import ResetPasswordViewContainer from '../ResetPasswordViewContainer';
import SetPasswordContainer from '../SetPasswordContainer';

export interface AuthModalProps extends DialogProps {
  variant: 'login' | 'mlLogin' | 'resetPassword' | 'join' | 'interest' | 'userInfo' | 'setPassword' | 'createAccount';
  onSuccessfullyProceed?: () => void;
}

const AuthModal = (props: AuthModalProps) => {
  const { variant: initialVariant = 'login', onSuccessfullyProceed, onClose, ...other } = props;
  const [variant, setVariant] = useState<AuthModalProps['variant']>(initialVariant);
  const [showLoginButton, setShowLoginButton] = useState(initialVariant !== 'join');
  const isHardUser = useSelector(AuthSelectors.isHardUser);

  const handleLoginSwitch = () => setVariant('login');
  const handleMlLoginSwitch = () => setVariant('mlLogin');
  const handleJoinSwitch = () => {
    setShowLoginButton(true);
    setVariant('join');
  };
  const handleInterestSwitch = () => setVariant('interest');
  const handleUserInfoSwitch = () => setVariant('userInfo');
  const handleSetPasswordSwitch = () => setVariant('setPassword');
  const handleClose = (isProceed?: boolean) => {
    setVariant('login');
    if (isProceed && onSuccessfullyProceed && isHardUser) onSuccessfullyProceed();
    onClose({}, null);
  };
  const handleOnForgotPassword = () => setVariant('resetPassword');

  const views: Record<AuthModalProps['variant'], React.ReactNode> = {
    login: (
      <LoginViewContainer
        onSwitch={handleJoinSwitch}
        onMlSwitch={handleMlLoginSwitch}
        onClose={handleClose}
        onForgotPassword={handleOnForgotPassword}
      />
    ),
    mlLogin: <MagicLinkLoginViewContainer onSwitch={handleJoinSwitch} onBack={handleLoginSwitch} />,
    resetPassword: <ResetPasswordViewContainer onSwitch={handleJoinSwitch} onBack={handleLoginSwitch} />,
    join: (
      <JoinViewContainer
        onSwitch={handleLoginSwitch}
        onNext={handleInterestSwitch}
        showSwitchButton={showLoginButton}
      />
    ),
    createAccount: <JoinViewContainer onSwitch={handleLoginSwitch} onNext={handleInterestSwitch} showSwitchButton />,
    interest: <InterestViewContainer onNext={handleUserInfoSwitch} />,
    userInfo: <UserInfoContainer onNext={handleSetPasswordSwitch} />,
    setPassword: <SetPasswordContainer onClose={() => handleClose(true)} />,
  };

  return (
    <Dialog onClose={() => handleClose()} PaperComponent={PaperComponent} maxWidth="lg" {...other}>
      {views[variant]}
      <CloseButton onClose={() => handleClose()} />
    </Dialog>
  );
};

export default AuthModal;
