import React from 'react';
import { Fade, Grow, Link, Typography } from '@mui/material';
import { useTranslation } from 'i18n';
import CircleSector from 'components/common/CircleSector';
import palette from 'theme/palette';
import IconArrowLeft from 'components/svg/IconArrowLeft.svg';
import { makeStyles } from 'tss-react/mui';
import AuthLayout from './AuthLayout';
import AuthSwitch from './AuthSwitch';
import AuthContent from './AuthContent';
import AuthSubmitContent from './AuthSubmitContent';
import ImageTile from './ImageTile';
import TextTile from './TextTile';
import MagicLinkLoginForm from './MagicLinkLoginForm';

export interface MagicLinkLoginViewProps {
  isSending: boolean;
  isSuccessfullySubmitted: boolean;
  submittedEmail: string;
  onSwitch: () => void;
  onSubmit: (values: any) => void;
  onBack: () => void;
}

const useStyles = makeStyles()(() => ({
  backButton: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    paddingBottom: 12,
    cursor: 'pointer',
  },
  switchButton: {
    borderRadius: 32,
  },
}));

const helpUrl = 'https://help.jlive.app/knowledge-base/magiclinks';

const MagicLinkLoginView: React.FC<React.PropsWithChildren<MagicLinkLoginViewProps>> = (props) => {
  const { isSending, isSuccessfullySubmitted, submittedEmail, onSwitch, onSubmit, onBack } = props;
  const [t] = useTranslation('public');
  const { classes } = useStyles();
  const hasForm = !isSuccessfullySubmitted;

  return (
    <AuthLayout
      content={
        <div>
          <Grow in={!hasForm} mountOnEnter>
            <div>
              <AuthSubmitContent variant="login" email={submittedEmail} passwordText />
            </div>
          </Grow>
          {hasForm && (
            <>
              <Link className={classes.backButton} underline="none" onClick={onBack}>
                <IconArrowLeft />
                <Typography component="span" style={{ fontWeight: 500 }} variant="body2">
                  {t<string>('auth.loginView.back')}
                </Typography>
              </Link>
              <AuthContent
                title={t<string>('auth.loginView.magicLinkTitle')}
                description={
                  <div style={{ maxWidth: 416 }}>
                    {t<string>('auth.loginView.magicLinkDescription')}
                    <span>
                      <Link style={{ fontWeight: 700 }} href={helpUrl} underline="none" target="_blank">
                        {t<string>('auth.loginView.magicLinkDescriptionLink')}
                      </Link>
                    </span>
                  </div>
                }
                form={<MagicLinkLoginForm isSending={isSending} onSubmit={onSubmit} />}
              />
            </>
          )}
        </div>
      }
      switcher={
        <Fade in={hasForm} appear={false}>
          <div>
            <AuthSwitch
              label={t<string>('auth.loginView.switch.label')}
              ButtonProps={{
                className: classes.switchButton,
                onClick: onSwitch,
                color: 'primary',
                children: t('auth.loginView.switch.button'),
              }}
            />
          </div>
        </Fade>
      }
      images={
        <>
          <TextTile logo color="secondary" />
          <ImageTile src="/static/img/xavier-mouton-photographie-ry_sD0P1ZL0.jpeg">
            <CircleSector
              variant="top-right"
              position="absolute"
              backgroundColor={palette.custom.green.main}
              radius={42}
              top={14}
              left={12}
            />
            <CircleSector
              variant="top-left"
              position="absolute"
              backgroundColor={palette.primary.main}
              radius={38}
              top="50%"
              right={26}
            />
          </ImageTile>
          <ImageTile src="/static/img/priscilla-du-preez-mKJUoZPy70I.jpeg">
            <CircleSector
              variant="top-left"
              position="absolute"
              backgroundColor={palette.custom.purple.main}
              radius={60}
              top={18}
              right={56}
            />
          </ImageTile>
          <TextTile text={t<string>('auth.loginView.text')} color="primary" />
        </>
      }
    />
  );
};

export default MagicLinkLoginView;
