import React from 'react';
import * as Yup from 'yup';
import { emailSchema, nameSchema } from 'utils/validation';
import withFormSubmission, { MergedProps } from 'components/common/withFormSubmission';

export interface IValues {
  age: boolean;
  email: string;
  first_name: string;
  last_name: string;
  primary_market: number;
  is_other_primary_market: boolean;
}

export interface IProps {
  user?: any;
  disabledEmail?: boolean;
  withButton?: boolean;
  onSubmit: (values: Omit<IValues, 'age'>) => void;
  bottom?: (disabled: boolean) => React.ReactNode;
}

export type FormComponentProps = MergedProps<IProps, IValues>;

const withJoinForm = withFormSubmission<IProps, IValues>({
  enableReinitialize: true,
  validationSchema: () =>
    Yup.object({
      age: Yup.bool().oneOf([true]),
      email: emailSchema().required(),
      first_name: nameSchema().required(),
      last_name: nameSchema().required(),
      primary_market: Yup.number().nullable().required(),
    }),
  mapPropsToValues: ({ user }) => ({
    age: false,
    email: user?.email || '',
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    primary_market: user?.is_other_primary_market ? 0 : user?.primary_market?.id || null,
    is_other_primary_market: user?.is_other_primary_market,
  }),
  handleSubmit: (values, { props: { onSubmit } }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { age, ...rest } = values;
    if (rest.primary_market === 0) {
      rest.primary_market = null;
      rest.is_other_primary_market = true;
    } else {
      rest.is_other_primary_market = false;
    }
    onSubmit(rest);
  },
});

export default withJoinForm;
