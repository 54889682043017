import { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';

const bootstrapURLKeys = {
  key: process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY,
  libraries: 'places',
};

const buildHook = (serviceName, requestName, initialResponse, getParams) => (value) => {
  const [request, setRequest] = useState(null);
  const [response, setResponse] = useState(initialResponse);

  useEffect(() => {
    let active = true;

    GoogleMapReact.googleMapLoader(bootstrapURLKeys, false).then((maps) => {
      if (!maps?.places) return;
      const { [serviceName]: Service } = maps.places;
      if (!Service) return;
      if (active) {
        const service = new Service(document.createElement('div'));
        const request = (...args) => {
          if (active) {
            service[requestName](getParams(...args), (response) => {
              if (active) {
                setResponse(response || initialResponse);
              }
            });
          }
        };

        setRequest(() => request);
      }
    });

    return () => {
      active = false;
    };
  }, []);

  useEffect(() => {
    if (!request) return;
    if (!value) {
      setResponse(initialResponse);
      return;
    }
    request(value);
  }, [value, request]);

  return [response];
};

export const usePlacePredictions = buildHook('AutocompleteService', 'getPlacePredictions', [], (value) => {
  if (typeof value === 'string') return { input: value };
  return value;
});

export const usePlaceDetails = buildHook('PlacesService', 'getDetails', null, (value) => {
  if (value && typeof value === 'object' && value.place_id) return { placeId: value.place_id };
  return value;
});
