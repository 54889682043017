import React from 'react';

const nodeMap = (node: React.ReactNode, fn: (child: React.ReactNode, index: number) => React.ReactNode): any => {
  if (Array.isArray(node)) return React.Children.map(node, fn);
  if (React.isValidElement(node)) {
    if (React.Children.only(node)) return nodeMap(node.props.children, fn);
    return React.Children.map(node, fn);
  }

  return null;
};

export default nodeMap;
