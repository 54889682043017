import { useMemo } from 'react';
import { getTranslationName } from 'utils/format';

export const getTranslationCount = (initialValues, values, defaultLanguage, translationLanguage) =>
  Object.keys(initialValues).reduce((acc, key) => {
    const initialValue = initialValues[key];
    const value = values[key];
    if (typeof initialValue === 'object' && initialValue) {
      return acc + getTranslationCount(initialValue, value, defaultLanguage, translationLanguage);
    }
    const language = key.substring(key.length, key.length - 2);
    const name = key.substring(0, key.length - 3);
    if (
      language === defaultLanguage &&
      initialValues[getTranslationName(name, defaultLanguage)] &&
      !values[getTranslationName(name, translationLanguage)]
    ) {
      return acc + 1;
    }
    return acc;
  }, 0);

const useTranslationsCount = (initValues, values, defaultLanguage, translationLanguage) =>
  useMemo(
    () => getTranslationCount(initValues, values, defaultLanguage, translationLanguage),
    [initValues, values, defaultLanguage, translationLanguage],
  );

export default useTranslationsCount;
