import React from 'react';
import { Dialog, DialogContent, DialogContentText, DialogProps, Theme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'i18n';

import CustomDialogTitle from 'components/common/CustomDialogTitle';
import MfaCodeModalContainer from './MfaCodeModalContainer';

interface MfaCodeModalProps extends DialogProps {
  email?: string;
  password?: string;
  onClose: any;
}

const MfaCodeModal = (props: MfaCodeModalProps) => {
  const { onClose, email, password, ...other } = props;
  const [t] = useTranslation('public');

  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));

  const handleCloseClick: React.MouseEventHandler = (e) => {
    onClose(e, null);
  };

  return (
    <Dialog fullScreen={isMobile} onClose={onClose} fullWidth {...other}>
      <CustomDialogTitle onClose={handleCloseClick}>{t<string>('auth.authMfaModal.title')}</CustomDialogTitle>
      <DialogContent>
        <DialogContentText>{t<string>('auth.authMfaModal.message')}</DialogContentText>
        <MfaCodeModalContainer email={email} password={password} onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
};

export default MfaCodeModal;
