export const TITLE_OPTIONS = ['dr', 'mr', 'mrs', 'ms', 'miss', 'rabbi', 'cantor'];

export const PHONE_OPTIONS = ['phone', 'work_phone', 'home_phone'];

export const LANGUAGE_OPTIONS = ['en', 'fr'];

export const LANGUAGE_OPTIONS_FULL = ['en', 'fr', 'ru', 'hb', 'sp', 'other'];

export const JEWISH_DESCENDANTS = ['ashkenazi', 'sephardic', 'mizrahi'];

export const MAIN_RELIGION_OPTIONS = [
  'reform_judaism',
  'conservative_judaism',
  'orthodox_judaism',
  'conservadox_judaism',
  'just_jewish',
  'not_jewish',
];

export const RELIGION_OPTIONS = [
  ' ',
  ...MAIN_RELIGION_OPTIONS,
  'modern_orthodox_judaism',
  'haredi_orthodox_judaism',
  'hasidic_judaism',
  'reconstructionist_judaism',
  'culturally_jewish',
  'traditional_judaism',
  'jewish_renewal',
  'other',
];

export const RELATIONSHIP_STATUS_OPTIONS = [
  '',
  'married',
  'civil_union',
  'single',
  'divorced',
  'engaged',
  'in_relationship',
  'complicated',
  'widowed',
];

export const RELATIONSHIP_TO_BUYER_OPTIONS = [
  'grandfather',
  'grandmother',
  'grandchild',
  'mother',
  'father',
  'son',
  'daughter',
  'brother',
  'sister',
  'spouse',
  'other',
];

export const GENDER_OPTIONS = ['m', 'f', 'non_binary', 'no_answer', 'other'];

export const ADMIN_OPTIONS = [
  {
    group_id: 4,
    object_type: 'organization',
  },
  {
    group_id: 5,
    object_type: 'organization',
  },
  {
    group_id: 6,
    object_type: 'organization',
  },
];

export const CTA_BUTTON_OPTIONS = [
  'get_tickets',
  'signup',
  'register',
  'volunteer',
  'more_info',
  'go',
  'learn_more',
  'no_registration',
  'apply',
  'donate',
];

export const TYPE_OF_REPORTS = ['orders', 'events', 'registrants'];

export const CHILDREN_IN_HOUSEHOLD = [0, 1, 2, 3, 4, 5, 6];

export const PHONE_TYPE = ['mobile', 'home', 'work'];
