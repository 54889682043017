import { useCallback, useEffect } from 'react';

const useBeforeUnload = (callback, deps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedCallback = useCallback(callback, deps);

  useEffect(() => {
    const listener = (e) => {
      e.preventDefault();

      let message = '';
      if (memoizedCallback) {
        message = memoizedCallback() || message;
      }

      e.returnValue = message;
      return message;
    };

    window.addEventListener('beforeunload', listener);

    return () => {
      window.removeEventListener('beforeunload', listener);
    };
  }, [memoizedCallback]);
};

export default useBeforeUnload;
