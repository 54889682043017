import React, { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import { AuthSelectors } from 'store/selectors';
import { AuthActions } from 'store/actions';

import MfaCodeForm from '../MfaCodeForm/MfaCodeForm';

interface MfaCodeModalContainerForm {
  email?: string;
  password?: string;
  onClose: () => void;
}

const MfaCodeModalContainer: React.FC<React.PropsWithChildren<MfaCodeModalContainerForm>> = ({
  email,
  password,
  onClose,
}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const loginState = useSelector(AuthSelectors.loginState);
  const { isPending, isFulfilled, error } = useSelector(AuthSelectors.loginMagicLinkState);
  const isLogin = Boolean(email && password);

  const { query } = router;
  const handleSubmit = useCallback(
    (values: any) => {
      if (isLogin) {
        dispatch(AuthActions.login.request({ params: { email, password, ...values } }));
      } else
        dispatch(
          AuthActions.loginMagicLink.request({ params: { magic_link_token: query.magic_link_token, ...values } }),
        );
    },
    [dispatch, email, isLogin, password, query.magic_link_token],
  );

  useEffect(() => {
    if (loginState.isFulfilled && !loginState.error) {
      onClose();
    }
  }, [loginState.error, loginState.isFulfilled, onClose]);

  useEffect(() => {
    if (isFulfilled && !error) {
      onClose();
    }
  }, [isFulfilled, error, onClose]);

  return <MfaCodeForm onSubmit={handleSubmit} isSending={isPending} />;
};

export default MfaCodeModalContainer;
