import { useMemo, RefObject } from 'react';
import { Theme, useMediaQuery, useScrollTrigger } from '@mui/material';

const callbackMediaQuery = (theme: Theme) => theme.breakpoints.down('md');

const useMobileCollapse = (scrollRef: RefObject<HTMLElement>): boolean => {
  const scrollEl = scrollRef.current;

  const options = useMemo(
    () => ({
      threshold: 34,
      disableHysteresis: true,
      target: scrollEl,
    }),
    [scrollEl],
  );

  const isMobile = useMediaQuery(callbackMediaQuery);
  const trigger = useScrollTrigger(options);

  return trigger && isMobile;
};

export default useMobileCollapse;
