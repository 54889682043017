import React from 'react';
import { TFunction } from 'next-i18next';
import { fieldToTextField } from 'formik-mui';
import { FieldProps as FormikFieldProps } from 'formik';
import { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField/TextField';
import { getHelperText, getLabel, getPlaceholder } from './translations';

export type TextFieldProps = Omit<MuiTextFieldProps, 'ref' | 'label' | 'placeholder'>;

export interface FieldProps extends FormikFieldProps {
  namespace?: string;
  context?: string;
  label?: string | boolean;
  placeholder?: string | boolean;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const useTextFieldProps = <P extends TextFieldProps & FieldProps>(props: P, t: TFunction) => {
  const { label, placeholder, namespace, context, ...rest } = props;
  const { name, onBlur, helperText, ...fieldProps } = fieldToTextField(rest);

  if (fieldProps.value === undefined || fieldProps.value === null) fieldProps.value = '';

  const handleBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    if (e instanceof Event) return onBlur(e);
    props.form.setFieldTouched(name, true);
  };

  return {
    name,
    ...fieldProps,
    label: getLabel(t, namespace, context, name, label),
    placeholder: getPlaceholder(t, namespace, context, name, placeholder),
    helperText: getHelperText(t, namespace, context, helperText),
    onBlur: handleBlur,
  };
};

export default useTextFieldProps;
