import { useEffect, useState } from 'react';
import { getVideoThumb } from 'utils/video';

const useVideoThumb = (url: string) => {
  const [thumb, setThumb] = useState<string>();

  useEffect(() => {
    getVideoThumb(url).then((res) => setThumb(res));
  }, [url]);

  return thumb;
};

export default useVideoThumb;
