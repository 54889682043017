import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CountriesActions } from 'store/actions';
import { CountriesSelectors } from 'store/selectors';
import { createStructuredSelector } from 'reselect';

const selector = createStructuredSelector({
  countriesState: CountriesSelectors.countriesState,
  hasCountries: CountriesSelectors.hasCountries,
});

const useCountries = () => {
  const dispatch = useDispatch();
  const { countriesState, hasCountries } = useSelector(selector);

  useEffect(() => {
    if (!hasCountries) {
      dispatch(CountriesActions.getCountries.request());
    }
  }, [dispatch, hasCountries]);

  return {
    countries: countriesState.entities,
    isCountriesFetching: countriesState.isFetching,
  };
};

export default useCountries;
