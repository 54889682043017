import React, { useEffect } from 'react';
import { useTranslation } from 'i18n';
import CircleSector from 'components/common/CircleSector';
import palette from 'theme/palette';
import { makeStyles } from 'tss-react/mui';
import { Grid } from '@mui/material';
import AuthLayout from './AuthLayout';
import AuthSwitch from './AuthSwitch';
import AuthContent from './AuthContent';
import ImageTile from './ImageTile';
import TextTile from './TextTile';
import LoginForm from './LoginForm';

export interface LoginViewProps {
  isSending: boolean;
  isSuccessfullySubmitted: boolean;
  onMlSwitch: () => void;
  onSwitch: () => void;
  onSubmit: (values: any) => void;
  onClose: (isProceed?: boolean) => void;
  onForgotPassword: () => void;
  errorMessage?: string;
}

const useStyles = makeStyles()(() => ({
  authContent: {
    textAlign: 'left',
  },
  switchButton: {
    borderRadius: 32,
  },
}));

const LoginView: React.FC<React.PropsWithChildren<LoginViewProps>> = (props) => {
  const {
    isSending,
    isSuccessfullySubmitted,
    errorMessage,
    onMlSwitch,
    onSwitch,
    onSubmit,
    onClose,
    onForgotPassword,
  } = props;
  const [t] = useTranslation('public');
  const { classes } = useStyles();

  useEffect(() => {
    if (isSuccessfullySubmitted) onClose(true);
  }, [isSuccessfullySubmitted, onClose]);

  return (
    <AuthLayout
      content={
        <Grid container justifyContent="center">
          <Grid item style={{ width: 264 }}>
            <AuthContent
              title={t<string>('auth.loginView.title')}
              description={t<string>('auth.loginView.description')}
              form={
                <LoginForm
                  errorMessage={errorMessage}
                  isSending={isSending}
                  onSubmit={onSubmit}
                  onMlSwitch={onMlSwitch}
                  onForgotPassword={onForgotPassword}
                />
              }
            />
          </Grid>
        </Grid>
      }
      switcher={
        <AuthSwitch
          label={t<string>('auth.loginView.switch.label')}
          ButtonProps={{
            className: classes.switchButton,
            onClick: onSwitch,
            color: 'primary',
            children: t<string>('auth.loginView.switch.button'),
          }}
        />
      }
      images={
        <>
          <TextTile logo color="secondary" />
          <ImageTile src="/static/img/xavier-mouton-photographie-ry_sD0P1ZL0.jpeg">
            <CircleSector
              variant="top-right"
              position="absolute"
              backgroundColor={palette.custom.green.main}
              radius={42}
              top={14}
              left={12}
            />
            <CircleSector
              variant="top-left"
              position="absolute"
              backgroundColor={palette.primary.main}
              radius={38}
              top="50%"
              right={26}
            />
          </ImageTile>
          <ImageTile src="/static/img/priscilla-du-preez-mKJUoZPy70I.jpeg">
            <CircleSector
              variant="top-left"
              position="absolute"
              backgroundColor={palette.custom.purple.main}
              radius={60}
              top={18}
              right={56}
            />
          </ImageTile>
          <TextTile text={t<string>('auth.loginView.text')} color="primary" />
        </>
      }
    />
  );
};

export default LoginView;
