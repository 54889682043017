import React from 'react';
import { Link } from '@mui/material';
import { Trans } from 'i18n';
import { routes } from 'utils/routing';

export type AgreementProps = Omit<React.ComponentProps<typeof Trans>, 'i18nKey' | 'ns'>;

const Agreement: React.FC<React.PropsWithChildren<AgreementProps>> = (props) => (
  <Trans i18nKey="statement.agreement" ns="common" {...props}>
    <Link href={routes.privacyPolicy().link} target="_blank" />
    <Link href={routes.termsOfUse().link} target="_blank" />
  </Trans>
);

export default Agreement;
