import React from 'react';
import { CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface LoadingProps {
  fullScreen?: boolean;
  className?: string;
}

const useStyles = makeStyles()((theme) => ({
  progressContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(6),
    height: `100%`,
    '&.fullScreen': {
      margin: 0,
      height: `calc(100vh - ${theme.mixins.appBar.minHeight}px)`,
    },
  },
}));

const Loading: React.FC<React.PropsWithChildren<LoadingProps>> = (props) => {
  const { className, fullScreen } = props;
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.progressContainer, className, { fullScreen })}>
      <CircularProgress />
    </div>
  );
};

export default Loading;
