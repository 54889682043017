import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IdType } from 'store/entities/types';

type EntityState = { entity: any; isFetching: boolean; fetchingError: any };
type EntitiesState = { entities: any[]; count?: number; isFetching: boolean; isFetched: boolean; fetchingError: any };

export const makeEntityLoader =
  <State, Selected extends EntityState>(
    actions: any,
    stateSelector: (state: State, id: any, params?: any) => Selected,
    resetEntity = true,
  ) =>
  (fn: () => [IdType, any?] | IdType, deps?: any[]) => {
    const dispatch = useDispatch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizedParams = useMemo(fn, deps);
    let id: IdType = memoizedParams as IdType;
    let params: any;
    if (Array.isArray(memoizedParams)) [id, params] = memoizedParams;

    const memoizedStateSelector = useCallback((state: State) => stateSelector(state, id, params), [id, params]);
    const state = useSelector(memoizedStateSelector);
    const needLoad = !state.entity && !state.isFetching && !state.fetchingError;

    const load = useCallback(() => {
      dispatch(actions.request({ id, params }));
    }, [dispatch, id, params]);

    const reset = useCallback(() => {
      dispatch(actions.reset({ id, params }));
    }, [dispatch, id, params]);

    useEffect(() => {
      if (needLoad) load();
    }, [load, needLoad]);

    useEffect(() => {
      if (resetEntity) {
        return () => {
          reset();
        };
      }
    }, [reset]);

    return state;
  };

export const makeEntitiesLoader =
  <State, Selected extends EntitiesState>(
    actions: any,
    stateSelector: (state: State, params: any) => Selected,
    resetEntity = true,
    initialLoad = true,
  ) =>
  (fn: () => any = () => undefined, deps: any[] = []) => {
    const dispatch = useDispatch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const params = useMemo(fn, deps);
    const memoizedStateSelector = useCallback((state: State) => stateSelector(state, params), [params]);
    const state = useSelector(memoizedStateSelector);
    const needLoad = initialLoad && !state.isFetched && !state.isFetching;

    const load = useCallback(() => {
      dispatch(actions.request({ params }));
    }, [dispatch, params]);

    const reset = useCallback(() => {
      dispatch(actions.reset({ params }));
    }, [dispatch, params]);

    useEffect(() => {
      if (needLoad) load();
    }, [load, needLoad]);

    useEffect(() => {
      if (resetEntity) {
        return () => {
          reset();
        };
      }
    }, [reset]);

    return state;
  };
