import { TFunction } from 'next-i18next';

type HelperText = any | ((t: TFunction, namespace: string, context: string) => string);

const translate = (t: TFunction, key: string, namespace: string, context: string, field: string) => {
  let path = key;
  if (namespace) path = `${path}.${namespace}`;
  const name = field
    .split('.')
    .pop()
    .replace(/(<([^>]+)>)/gi, '');

  return t(`${path}.${name}`, { context });
};

export const getHelperText = (t: TFunction, namespace: string, context: string, helperText: HelperText) => {
  if (typeof helperText === 'function') return helperText(t, namespace, context);
  return helperText;
};

export const getLabel = (t: TFunction, namespace: string, context: string, name: string, label: string | boolean) => {
  if (typeof label === 'boolean') return translate(t, 'common:field.label', namespace, context, name);
  return label;
};

export const getPlaceholder = (
  t: TFunction,
  namespace: string,
  context: string,
  name: string,
  placeholder: string | boolean,
) => {
  if (typeof placeholder === 'boolean') return translate(t, 'common:field.placeholder', namespace, context, name);
  return placeholder;
};
